import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {graphql, Link, StaticQuery} from 'gatsby';
import {getSectorLink} from '../../helpers/links';
import {getTranslated} from '../../helpers/lang';
import {getSectorByID} from '../../selectors/data';

import './style.scss';
import {BgImage as BackgroundImage} from 'gbimage-bridge';

const SectorItem = ({id, lang}) => {
	const sectorData = getSectorByID(id);
	return (
		<StaticQuery
			query={graphql`
				{
					images: allFile(
						filter: {
							extension: {regex: "/(jpg)|(jpeg)|(png)/"}
							relativeDirectory: {eq: "pages/work/sectors"}
						}
					) {
						edges {
							node {
								relativePath
								name
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							}
						}
					}
				}
			`}
			render={data => {
				const image = data.images.edges.find(n => {
					return sectorData.imagePath?.includes(n.node.relativePath);
				});
				const classes = classnames(
					'gst-sector-item gst-grid-item',
					sectorData.theme
				);

				return (
					<BackgroundImage
						Tag="div"
						className={classes}
						image={
							image
								? image.node.childImageSharp.gatsbyImageData
								: ['transparent']
						}
						backgroundColor={`#fff`}
					>
						<Link to={getSectorLink(sectorData.url)}>
							<h3>{getTranslated(sectorData.title, lang)}</h3>
							<p>{getTranslated(sectorData.description, lang)}</p>
						</Link>
					</BackgroundImage>
				);
			}}
		/>
	);
};

SectorItem.propTypes = {
	id: PropTypes.string.isRequired,
	lang: PropTypes.string,
};

export default SectorItem;
