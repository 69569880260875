import React from 'react';
import {Link, graphql, useStaticQuery} from 'gatsby';

import {lang} from '../../helpers/lang';
import {getCaseStudies, getSectors, getServices} from '../../selectors/data';

import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import Content from '../../components/Content';
import Hero from '../../components/Hero';
import GridList from '../../components/GridList';
import Block from '../../components/Block';
import SectorItem from '../../components/SectorItem';
import CaseStudyItem from '../../components/CaseStudyItem';
import ServiceItem from '../../components/ServiceItem';
import PageNav from '../../components/PageNav';

import style from './style.scss';

const sectors = getSectors();
const services = getServices();
const caseStudies = getCaseStudies();
const renderSectors = (count, lang) => {
	return sectors
		.slice(0, count)
		.map(sector => <SectorItem id={sector.key} key={sector.key} lang={lang} />);
};

const renderCaseStudies = (count, lang) => {
	return caseStudies
		.slice(0, count)
		.map(sector => (
			<CaseStudyItem id={sector.key} key={sector.key} lang={lang} />
		));
};

const renderServices = (count, lang) => {
	return services
		.slice(0, count)
		.map(sector => (
			<ServiceItem id={sector.key} key={sector.key} lang={lang} />
		));
};

const ProjectsPage = () => {
	const data = useStaticQuery(graphql`
		{
			workBackground: file(relativePath: {eq: "pages/work/river3.jpg"}) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Our work" />
			<PageNav>
				<Link to="#sectors">
					<span>Sectors</span>
				</Link>
				<Link to="#case-studies">
					<span>Case studies</span>
				</Link>
				<Link to="#services">
					<span>Services</span>
				</Link>
			</PageNav>
			<Hero
				coloredOverlay
				className="gst-work-hero"
				backgroundImageFluid={
					data.workBackground.childImageSharp.gatsbyImageData
				}
			>
				<h1>Earth observation, full service</h1>
				<p>
					We pride ourselves on delivering tailor-made solutions leveraging EO
					data across the board. Any industry, any scale.
				</p>
				{/*<p>*/}
				{/*Take a look on our work in your <Link to="#">sector</Link>, find*/}
				{/*inspiration in specific <Link to="#">case studies</Link>, or check*/}
				{/*that we can provide the <Link to="#">service</Link> you already know*/}
				{/*you need.*/}
				{/*</p>*/}
			</Hero>

			<Block light className="gst-block-work">
				<Content>
					<h2 id="sectors">Sectors</h2>
					<GridList>{renderSectors(10, lang)}</GridList>
				</Content>
			</Block>

			{/*<Block style={{background: '#B9CDE6'}}>*/}
			<Block light accent className="gst-block-work">
				<Content>
					<h2 id="case-studies">Case studies</h2>
					<GridList>{renderCaseStudies(4, lang)}</GridList>
				</Content>
			</Block>
			{/*<Block style={{background: '#EAD7B0'}}>*/}
			<Block dark accent className="gst-block-work">
				<Content>
					<h2 id="services">Products & services</h2>
					<GridList className={'gst-service-list'}>
						{renderServices(10, lang)}
					</GridList>
				</Content>
			</Block>
		</Layout>
	);
};

export default ProjectsPage;
