import React from 'react';
import PropTypes from 'prop-types';
import {graphql, Link, StaticQuery} from 'gatsby';

import {getCaseStudyLink, getSectorLink} from '../../helpers/links';
import {getTranslated} from '../../helpers/lang';
import {getCaseStudyByID, getSectorByID} from '../../selectors/data';

import './style.scss';
import classnames from 'classnames';
// import BackgroundImage from 'gatsby-background-image';
import {BgImage as BackgroundImage} from 'gbimage-bridge';
import {Icon} from '@gisatcz/ptr-atoms';

const CaseStudyItem = ({id, lang}) => {
	const caseStudyData = getCaseStudyByID(id);
	const sectorsData = caseStudyData.sectors?.map(sector => {
		return getSectorByID(sector);
	});

	return (
		<StaticQuery
			query={graphql`
				{
					images: allFile(
						filter: {
							extension: {regex: "/(jpg)|(jpeg)|(png)/"}
							relativeDirectory: {eq: "pages/work/caseStudies"}
						}
					) {
						edges {
							node {
								relativePath
								name
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							}
						}
					}
				}
			`}
			render={data => {
				const image = data.images.edges.find(n => {
					return caseStudyData.imagePath?.includes(n.node.relativePath);
				});
				const classes = classnames(
					'gst-case-study-item gst-grid-item',
					caseStudyData.theme
				);

				return (
					<div className={classes}>
						<Link to={getCaseStudyLink(caseStudyData.url)}>
							<BackgroundImage
								Tag="div"
								className="gst-case-study-item-image"
								image={
									image
										? image.node.childImageSharp.gatsbyImageData
										: ['transparent']
								}
								backgroundColor={`#fff`}
								style={{
									backgroundPosition: 'center center',
									backgroundSize: '100%',
								}}
							/>
							<div className="gst-case-study-item-body">
								<div className="gst-case-study-item-sectors">
									{sectorsData
										? sectorsData.map(sector => {
												const sectorClasses = classnames(
													'gst-case-study-item-sector',
													sector.theme || null
												);

												return (
													<span className={sectorClasses}>
														{getTranslated(sector.title, lang)}
													</span>
												);
										  })
										: null}
								</div>
								<h3>{getTranslated(caseStudyData.title, lang)}</h3>
								<p>{getTranslated(caseStudyData.description, lang)}</p>
							</div>
							<div className="gst-case-study-item-footer">
								<div>
									<Icon icon="circle" />
									<span>{caseStudyData.client}</span>
								</div>
								<div>
									<Icon icon="calendar" />
									<span>{caseStudyData.period}</span>
								</div>
							</div>
						</Link>
					</div>
				);
			}}
		/>
	);
};

CaseStudyItem.propTypes = {
	id: PropTypes.string.isRequired,
	lang: PropTypes.string,
};

export default CaseStudyItem;
